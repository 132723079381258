import React from 'react'

import { Container, Typography } from '@material-ui/core'

const NotFound = () => {
  return (
    <Container>
      <div>
        <Typography variant='h2' align='center' gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant='h4' align='center'>
          Sorry, this page does not exist
        </Typography>
      </div>
    </Container>
  )
}

export default NotFound
