import React, { useState, useContext, useEffect, Fragment } from 'react'

import {
  Container,
  List,
  ListItem,
  ListItemText,
  Fab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'

import { AppContext } from '../../contexts/AppContext'
import { ItemContext } from '../../contexts/ItemContext'
import { StoreContext } from '../../contexts/StoreContext'

import Preloader from '../layout/Preloader'
import Item from './Item'

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}))

const Items = ({ value }) => {
  const classes = useStyles()

  const { showBought } = useContext(AppContext)

  const { items, getItems, addItem, loading } = useContext(ItemContext)

  // Manipulate list items shown depending on state of showBought switch
  let itemsList = []

  if (!loading && items.length > 0 && showBought) {
    if (value === 0) {
      itemsList = items
    } else {
      itemsList = items.filter((item) => item.store_id === value)
    }
  } else if (!loading && items.length > 0) {
    if (value === 0) {
      itemsList = items.filter((item) => !item.bought)
    } else {
      itemsList = items.filter(
        (item) => item.store_id === value && !item.bought
      )
    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line
  }, [])

  // code for add item form
  const { stores } = useContext(StoreContext)

  const [name, setName] = useState('')

  const [storeID, setStoreID] = useState('')

  const [dialogOpen, setDialogOpen] = useState(false)

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setName('')
    setStoreID('')
    setDialogOpen(false)
  }

  const handleAddSubmit = (e) => {
    e.preventDefault()

    const data = {
      name: name,
      bought: false,
      store_id: storeID,
    }

    addItem(data)

    // Clear fields
    setName('')
    setStoreID('')

    handleDialogClose()
  }

  const addItemForm = (
    <Dialog open={dialogOpen} onClose={handleDialogClose}>
      <DialogTitle>Add Item</DialogTitle>
      <DialogContent>
        <TextField
          type='text'
          label='Name'
          onChange={(e) => setName(e.target.value)}
          required
          fullWidth
          margin='normal'
          autoFocus
        />
        <FormControl fullWidth margin='normal'>
          <InputLabel required shrink htmlFor='store-select'>
            Store
          </InputLabel>
          <Select
            native
            onChange={(e) => setStoreID(e.target.value)}
            inputProps={{
              name: 'Store',
              id: 'store-select',
            }}
          >
            <option value=''></option>
            {stores.map((s) => (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleAddSubmit} color='primary'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )

  if (loading) {
    return <Preloader />
  }

  return (
    <Fragment>
      <Container>
        {!loading && itemsList.length === 0 && (
          <List>
            <ListItem>
              <ListItemText>No items to show...</ListItemText>
            </ListItem>
          </List>
        )}
        {!loading && itemsList.length > 0 && (
          <List>
            {itemsList.map((item) => (
              <Item key={item.id} item={item} />
            ))}
          </List>
        )}
      </Container>
      <Fab className={classes.fab} color='primary' onClick={handleDialogOpen}>
        <AddIcon />
      </Fab>
      {addItemForm}
    </Fragment>
  )
}

export default Items
