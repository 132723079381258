import React, { useState, useContext, Fragment } from 'react'

import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core'

import { ItemContext } from '../../contexts/ItemContext'
import { StoreContext } from '../../contexts/StoreContext'

const Item = ({ item }) => {
  const { updateItem, deleteItem } = useContext(ItemContext)

  const [bought, setBought] = useState(item.bought)

  const toggleBought = () => {
    setBought(!bought)

    const data = {
      bought: !bought,
    }

    updateItem(item.id, data)
  }

  // Code for edit item form
  const { stores } = useContext(StoreContext)

  const [name, setName] = useState(item.name)

  const [storeID, setStoreID] = useState(item.store_id)

  const [dialogOpen, setDialogOpen] = useState(false)

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const handleUpdateSubmit = (e) => {
    e.preventDefault()

    const data = {
      name: name,
      store_id: storeID,
    }

    updateItem(item.id, data)

    handleDialogClose()
  }

  const handleDeleteItem = () => {
    deleteItem(item.id)
    handleDialogClose()
  }

  const editItemForm = (
    <Dialog open={dialogOpen} onClose={handleDialogClose}>
      <DialogTitle>Edit Item</DialogTitle>
      <DialogContent>
        <TextField
          type='text'
          name='name'
          label='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          fullWidth
          margin='normal'
          autoFocus
        />
        <FormControl fullWidth margin='normal'>
          <InputLabel required shrink htmlFor='store-select'>
            Store
          </InputLabel>
          <Select
            native
            value={storeID}
            onChange={(e) => setStoreID(e.target.value)}
            inputProps={{
              name: 'Store',
              id: 'store-select',
            }}
          >
            {stores.map((s) => (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteItem} color='secondary'>
          Delete
        </Button>
        <Button onClick={handleDialogClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleUpdateSubmit} color='primary'>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <Fragment>
      <ListItem button onClick={handleDialogOpen}>
        <ListItemText primary={item.name} />
        <ListItemSecondaryAction>
          <Checkbox checked={bought} onClick={toggleBought} />
        </ListItemSecondaryAction>
      </ListItem>
      {editItemForm}
    </Fragment>
  )
}

export default Item
