import React, { useState, useContext, useEffect, Fragment } from 'react'

import {
  Typography,
  List,
  Fab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'

import { StoreContext } from '../../contexts/StoreContext'
import Preloader from '../layout/Preloader'

import Store from './Store'

const useStyles = makeStyles((theme) => ({
  storeList: {
    padding: 0,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
  },
}))

const Stores = () => {
  const classes = useStyles()

  const { stores, loading, getStores, addStore } = useContext(StoreContext)

  const [newStoreName, setNewStoreName] = useState('')

  const [dialogOpen, setDialogOpen] = useState(false)

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const onAddSubmit = (e) => {
    e.preventDefault()

    addStore({ name: newStoreName })

    handleDialogClose()
  }

  useEffect(() => {
    getStores()
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <div>
        {loading && <Preloader />}
        {!loading && stores && stores.length === 0 && (
          <Typography className='center'>No stores to show...</Typography>
        )}
        {!loading && stores && stores.length > 0 && (
          <List>
            {stores.map((store) => (
              <Store key={store.id} store={store} />
            ))}
          </List>
        )}
      </div>

      <Fab className={classes.fab} color='primary' onClick={handleDialogOpen}>
        <AddIcon />
      </Fab>

      {/* Add store dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Add Store</DialogTitle>
        <DialogContent>
          <TextField
            type='text'
            label='Store name'
            onChange={(e) => setNewStoreName(e.target.value)}
            required
            fullWidth
            margin='normal'
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={onAddSubmit} color='primary'>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default Stores
