import React, { createContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

export const AlertContext = createContext()

const AlertContextProvider = (props) => {
  const [alerts, setAlerts] = useState([])

  // Add Alert
  const addAlert = (msg, alertType, timeout = 5000) => {
    const id = uuidv4()

    setAlerts([...alerts, { msg, alertType, timeout, id }])

    setTimeout(
      () => setAlerts(alerts.filter((alert) => alert.id !== id)),
      timeout
    )
  }

  return (
    <AlertContext.Provider
      value={{
        alerts,
        addAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  )
}

export default AlertContextProvider
