import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  preloaderContainer: {
    marginTop: theme.spacing(16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const Preloader = () => {
  const classes = useStyles()

  return (
    <div className={classes.preloaderContainer}>
      <CircularProgress />
    </div>
  )
}

export default Preloader
