import React, { Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { CssBaseline } from '@material-ui/core/'

import AppContextProvider from './contexts/AppContext'
import AlertContextProvider from './contexts/AlertContext'
import AuthContextProvider from './contexts/AuthContext'
import StoreContextProvider from './contexts/StoreContext'
import ItemContextProvider from './contexts/ItemContext'

import Navbar from './components/layout/Navbar'
import Alerts from './components/layout/Alerts'
import Login from './components/auth/Login'
import Home from './components/layout/Home'
import Stores from './components/store/Stores'
import NotFound from './components/layout/NotFound'
import PrivateRoute from './components/routing/PrivateRoute'

function App() {
  return (
    <AppContextProvider>
      <AlertContextProvider>
        <AuthContextProvider>
          <StoreContextProvider>
            <ItemContextProvider>
              <Router>
                <Fragment>
                  <CssBaseline />
                  <Navbar />
                  <Alerts />
                  <Switch>
                    <Route exact path='/login' component={Login} />
                    <PrivateRoute exact path='/' component={Home} />
                    <PrivateRoute exact path='/stores' component={Stores} />
                    <Route component={NotFound} />
                  </Switch>
                </Fragment>
              </Router>
            </ItemContextProvider>
          </StoreContextProvider>
        </AuthContextProvider>
      </AlertContextProvider>
    </AppContextProvider>
  )
}

export default App
