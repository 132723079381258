import React, { useState, useContext, useEffect } from 'react'

import { Container, Typography, TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { AuthContext } from '../../contexts/AuthContext'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}))

const Login = (props) => {
  const classes = useStyles()

  const { login, isAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/')
    }
  })

  const [user, setUser] = useState({
    email: '',
    password: '',
  })

  const { email, password } = user

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value })

  const onSubmit = (e) => {
    e.preventDefault()

    login(email, password)
  }

  return (
    <Container maxWidth='xs'>
      <div className={classes.paper}>
        <Typography variant='h4' gutterBottom>
          Login
        </Typography>
        <form onSubmit={onSubmit} className={classes.form} autoComplete='off'>
          <TextField
            type='email'
            name='email'
            label='Email'
            onChange={onChange}
            required
            fullWidth
            margin='normal'
            autoFocus
          />
          <TextField
            type='password'
            name='password'
            label='Password'
            onChange={onChange}
            required
            fullWidth
            margin='normal'
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            Login
          </Button>
        </form>
      </div>
    </Container>
  )
}

export default Login
