import React, { useContext } from 'react'

import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

import { AlertContext } from '../../contexts/AlertContext'

const useStyles = makeStyles((theme) => ({
  alertContainer: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const Alerts = () => {
  const classes = useStyles()

  const { alerts } = useContext(AlertContext)

  return (
    alerts.length > 0 &&
    alerts.map((alert) => (
      <div className={classes.alertContainer} key={alert.id}>
        <Alert variant='filled' severity={alert.alertType}>
          {alert.msg}
        </Alert>
      </div>
    ))
  )
}

export default Alerts
