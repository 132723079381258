import React, { createContext, useState, useContext } from 'react'

import { fb } from '../services/firebase'
import { AlertContext } from './AlertContext'

import Preloader from '../components/layout/Preloader'

export const AuthContext = createContext()

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const [loading, setLoading] = useState(true)

  const { addAlert } = useContext(AlertContext)

  // Monitor authentication status and update state
  fb.auth().onAuthStateChanged((user) => {
    if (user) {
      setIsAuthenticated(true)
      setLoading(false)
    } else {
      setIsAuthenticated(false)
      setLoading(false)
    }
  })

  // Login user
  const login = async (email, password) => {
    try {
      await fb.auth().signInWithEmailAndPassword(email, password)
    } catch (err) {
      addAlert(err.message, 'error')
    }
  }

  // Logout
  const logout = async () => {
    try {
      await fb.auth().signOut()
    } catch (err) {
      addAlert(err.message, 'error')
    }
  }

  if (loading) {
    return <Preloader />
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
