import React, { createContext, useState, useContext } from 'react'

import { db } from '../services/firebase'
import { AlertContext } from './AlertContext'

export const StoreContext = createContext()

const StoreContextProvider = (props) => {
  const storesCol = db.collection('stores')

  const [stores, setStores] = useState([])
  const [loading, setLoading] = useState(true)

  const { addAlert } = useContext(AlertContext)

  // Get stores
  const getStores = async () => {
    try {
      let docs = []
      const snapshot = await storesCol.orderBy('name').get()
      await snapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id })
      })
      setStores(docs)
      setLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  // Add store
  const addStore = async (data) => {
    try {
      await storesCol.add(data)
      getStores()
    } catch (err) {
      console.error(err)
      addAlert(err.message, 'error')
    }
  }

  // Update store
  const updateStore = async (id, data) => {
    try {
      await storesCol.doc(id).update(data)
      getStores()
    } catch (err) {
      addAlert(err.message, 'error')
    }
  }

  // Delete store
  const deleteStore = async (id) => {
    try {
      await storesCol.doc(id).delete()
      getStores()
    } catch (err) {
      addAlert(err.message, 'error')
    }
  }

  return (
    <StoreContext.Provider
      value={{
        stores,
        loading,
        getStores,
        addStore,
        updateStore,
        deleteStore,
      }}
    >
      {props.children}
    </StoreContext.Provider>
  )
}

export default StoreContextProvider
