import React, { useContext, useState, Fragment } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import {
  AppBar,
  Toolbar,
  useScrollTrigger,
  Typography,
  Link,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Switch,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { AppContext } from '../../contexts/AppContext'
import { AuthContext } from '../../contexts/AuthContext'

function ElevationScroll(props) {
  const { children } = props

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

const useStyles = makeStyles((theme) => ({
  appbar: {
    zIndex: theme.zIndex.modal + 1,
    marginBottom: theme.spacing(3),
  },
  navMenu: {
    marginLeft: 'auto',
  },
  drawer: {
    backgroundColor: theme.palette.primary.main,
  },
  drawerItem: {
    color: 'white',
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
}))

const Navbar = () => {
  const classes = useStyles()

  const theme = useTheme()
  const useDrawer = useMediaQuery(theme.breakpoints.down('md'))

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

  const { showBought, changeShowBought } = useContext(AppContext)

  const { isAuthenticated, logout } = useContext(AuthContext)

  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  const closeDrawer = () => {
    setDrawerOpen(false)
  }

  const handleLogout = () => {
    closeDrawer()
    logout()
  }

  const showBoughtSwitch = (
    <Fragment>
      <Tooltip title='Show bought items'>
        <Switch
          size='small'
          className={classes.showBoughtSwitch}
          checked={showBought}
          onChange={(e) => changeShowBought(e.target.checked)}
        />
      </Tooltip>
    </Fragment>
  )

  const menuAuthLinks = (
    <Fragment>
      <Button color='inherit' disableRipple component={RouterLink} to='/stores'>
        Stores
      </Button>
      <Button color='inherit' disableRipple onClick={handleLogout}>
        Logout
      </Button>
    </Fragment>
  )

  const drawerAuthLinks = (
    <Fragment>
      <ListItem button component={RouterLink} to='/' onClick={closeDrawer}>
        <ListItemText className={classes.drawerItem} disableTypography>
          Home
        </ListItemText>
      </ListItem>
      <ListItem
        button
        component={RouterLink}
        to='/stores'
        onClick={closeDrawer}
      >
        <ListItemText className={classes.drawerItem} disableTypography>
          Stores
        </ListItemText>
      </ListItem>
      <ListItem button onClick={handleLogout}>
        <ListItemText className={classes.drawerItem} disableTypography>
          Logout
        </ListItemText>
      </ListItem>
    </Fragment>
  )

  const menuGuestLinks = (
    <Fragment>
      <Button color='inherit' disableRipple component={RouterLink} to='/login'>
        Login
      </Button>
    </Fragment>
  )

  const drawerGuestLinks = (
    <Fragment>
      <ListItem button component={RouterLink} to='/login' onClick={closeDrawer}>
        <ListItemText className={classes.drawerItem} disableTypography>
          Login
        </ListItemText>
      </ListItem>
    </Fragment>
  )

  const menu = (
    <Fragment>
      <nav className={classes.navMenu}>
        {isAuthenticated && showBoughtSwitch}
        {isAuthenticated ? menuAuthLinks : menuGuestLinks}
      </nav>
    </Fragment>
  )
  const drawer = (
    <Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={drawerOpen}
        onClose={closeDrawer}
        onOpen={() => setDrawerOpen(true)}
        classes={{
          paper: classes.drawer,
        }}
      >
        <div className={classes.toolbarMargin} />
        <List>{isAuthenticated ? drawerAuthLinks : drawerGuestLinks}</List>
      </SwipeableDrawer>
      <nav className={classes.navMenu}>
        {isAuthenticated && showBoughtSwitch}
        <IconButton
          edge='start'
          className={classes.drawerToggleButton}
          color='inherit'
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
      </nav>
    </Fragment>
  )

  return (
    <Fragment>
      <ElevationScroll>
        <AppBar className={classes.appbar}>
          <Toolbar>
            <Typography variant='h5'>
              <Link
                component={RouterLink}
                to='/'
                color='inherit'
                underline='none'
              >
                Shopping List
              </Link>
            </Typography>
            {useDrawer ? drawer : menu}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </Fragment>
  )
}

export default Navbar
