import React, { createContext, useState } from 'react'

export const AppContext = createContext()

const AppContextProvider = (props) => {
  const [showBought, setShowBought] = useState(
    JSON.parse(localStorage.getItem('showBought'))
  )

  // Change showBought
  const changeShowBought = (value) => {
    localStorage.setItem('showBought', JSON.stringify(value))
    setShowBought(value)
  }

  return (
    <AppContext.Provider
      value={{
        showBought,
        changeShowBought,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
