import React, { createContext, useState, useContext } from 'react'

import { db } from '../services/firebase'
import { AlertContext } from './AlertContext'

export const ItemContext = createContext()

const ItemContextProvider = (props) => {
  const itemsCol = db.collection('items')

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  const { addAlert } = useContext(AlertContext)

  // Get items
  const getItems = async () => {
    try {
      let docs = []
      const snapshot = await itemsCol.orderBy('name').get()
      await snapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id })
      })
      setItems(docs)
      setLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  // Add item
  const addItem = async (data) => {
    try {
      await itemsCol.add(data)
      getItems()
    } catch (err) {
      console.error(err)
      addAlert(err.message, 'error')
    }
  }

  // Update item
  const updateItem = async (id, data) => {
    try {
      await itemsCol.doc(id).update(data)
      getItems()
    } catch (err) {
      addAlert(err.message, 'error')
    }
  }

  // Delete item
  const deleteItem = async (id) => {
    try {
      await itemsCol.doc(id).delete()
      getItems()
    } catch (err) {
      addAlert(err.message, 'error')
    }
  }
  return (
    <ItemContext.Provider
      value={{
        items,
        loading,
        getItems,
        addItem,
        updateItem,
        deleteItem,
      }}
    >
      {props.children}
    </ItemContext.Provider>
  )
}

export default ItemContextProvider
