import React, { useState, useContext, Fragment } from 'react'

import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  IconButton,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { StoreContext } from '../../contexts/StoreContext'

const Store = ({ store }) => {
  const { updateStore, deleteStore } = useContext(StoreContext)

  const [name, setName] = useState(store.name)

  const [dialogOpen, setDialogOpen] = useState(false)

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true)
  }

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }

  const handleUpdateCancel = (e) => {
    e.preventDefault()

    setName(store.name)

    handleDialogClose()
  }

  const handleUpdateSubmit = (e) => {
    e.preventDefault()

    const data = {
      name: name,
    }

    updateStore(store.id, data)

    handleDialogClose()
  }

  const handleDelete = () => {
    closeDeleteDialog()
    deleteStore(store.id)
  }

  return (
    <Fragment>
      <ListItem button onClick={handleDialogOpen}>
        <ListItemText primary={store.name} />
        <ListItemSecondaryAction>
          <IconButton onClick={openDeleteDialog}>
            <DeleteIcon fontSize='small' />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      {/* Edit store dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Edit Store</DialogTitle>
        <DialogContent>
          <TextField
            type='text'
            name='name'
            label='Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            fullWidth
            margin='normal'
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateCancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleUpdateSubmit} color='primary'>
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete confirmation dialog */}
      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Are you sure you want to delete the store?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleting a store will delete all items linked to the store
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleDelete} color='primary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default Store
