import React, { useState, useContext, useEffect } from 'react'

import { Tabs, Tab } from '@material-ui/core'

import { StoreContext } from '../../contexts/StoreContext'

import Preloader from './Preloader'
import Items from '../item/Items'

const Home = () => {
  const { stores, loading, getStores } = useContext(StoreContext)

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    getStores()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return <Preloader />
  }

  return (
    <div>
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='auto'
        >
          <Tab value={0} label='All' />
          {stores.map((store) => (
            <Tab key={store.id} value={store.id} label={store.name} />
          ))}
        </Tabs>
      </div>
      <Items value={value} />
    </div>
  )
}

export default Home
